import React, { useEffect, useState } from 'react'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined';
import EditMenuAD from '../../component/admin/EditMenuAD.component';
import GetListCuentasAD from '../../component/admin/GetListCuentasAD.api';
import AddCuentaAD from '../../component/admin/AddCuentaAD.component';

export default function MantenedorCuenta({token, rut}) {
  const [listaCTA, setListCTA] = useState([])
  const [rendr, setRendr] = useState(false)

  const getListfetch = async () => {
    const list = await GetListCuentasAD({token, rut})
    setListCTA(list)
  }
  if(rendr){
    getListfetch()
    setRendr(false)
  }

  useEffect(() => {
    getListfetch()
  }, [])
  //console.log(rut)
  return (
    <>
    <div className="container bsdw-cntinr fondo">
      <h5>Mantenedor Cuentas</h5>
    </div>
    <div className="container fondo bsdw-cntinr mt-3">
      <div className="row px-3 ">
        <div className="col-12 text-center align-content-center pb-2">
          <AddCuentaAD token={token} rut={rut} setRendr={setRendr} />
        </div>
        <div className="col-12 table-responsive">
          <table className="bg-white table table-sm table-bordered">
          <thead className="thead-light">
            <tr>
              <th className="text-center" scope="col">Planta</th>
              <th className="text-center" scope="col">Rut</th>
              <th className="text-center" scope="col">Nombre</th>
              <th className="text-center" scope="col">Correo</th>
              <th className="text-center" scope="col">Perfil</th>
              <th className="text-center" scope="col">Estado</th>
              <th className="text-center" scope="col">Editar</th>
            </tr>
          </thead>
          <tbody>
            {
              listaCTA.map((cta, index) =>
                <tr key={index}>
                  <td className="text-center">{cta.empresa}</td>
                  <td className="text-center">{cta.rut}</td>
                  <td className="text-center">{cta.nombre}</td>
                  <td className="text-center">{cta.user}</td>
                  <td className="text-center">{cta.rol}</td>
                  <td className="text-center">
                    {cta.state?
                    <AccountCircleOutlinedIcon color="success" />
                    :<NoAccountsOutlinedIcon color="error" />
                    }
                  </td>
                  <td className="text-center"><EditMenuAD token={token} data={cta} setRendr={setRendr}/></td>
                </tr>
               )
            }
          </tbody>
        </table>
        
        </div>
      </div>
    </div>
    </>
  )
}
