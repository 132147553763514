import React, { useState } from 'react';
import './App.css';
import Login from './view/Login.view';
import {BrowserRouter, Routes} from 'react-router-dom'
import CloseSession from './component/CloseSession.component'
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import CrearUser from './view/superUser/CrearUser.view';
import VerDatoPlantas from './view/superUser/VerDatoPlantas.view';
import NotFound from './view/NotFound.view';
import ForgetPass from './view/recovery/ForgetPass.view';
import CrearMedidor from './view/admin/CrearMedidor.view';
import MantenedorCuenta from './view/admin/MantenedorCuenta.view';
import MantenedorDatos from './view/admin/MantenedorDatos.view';
import VerDatos from './view/user/VerDatos.view';
import IngresoDatos from './view/admin/IngresoDatos.view';

import SettingsInputSvideoOutlinedIcon from '@mui/icons-material/SettingsInputSvideoOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import EqualizerIcon from '@mui/icons-material/Equalizer';


import { Box } from '@mui/system';
import { Divider, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer } from '@mui/material';
import GraficarChart from './view/admin/GraficarChart.view';

export const AuthContext = React.createContext();
const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  rut: null
}
const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      sessionStorage.setItem('user',action.payload.user);
      sessionStorage.setItem('token',action.payload.token);
      sessionStorage.setItem('rut',action.payload.rut);
      sessionStorage.setItem('empresa',action.payload.empresa);
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        rut:action.payload.rut,
        empresa:action.payload.empresa
      }
    case "LOGOUT":
      sessionStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        rut: null
      }

    default:
      return state
  }
}

function App() {
  const [menu, setMenu] = useState({right: false})
  const [state, dispatch] = React.useReducer(reducer, initialState);
  React.useEffect(() => {
    const user = sessionStorage.getItem('user') || null
    const token = sessionStorage.getItem('token') || null
    const rut = sessionStorage.getItem('rut') || null
    const empresa = sessionStorage.getItem('empresa') || null
    if(user && token){
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          token,
          rut,
          empresa
        }
      })
    }
  }, [])
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setMenu({ ...menu, [anchor]: open });
  };

  if(!state.token){
    return (
      <AuthContext.Provider
        value={{state, dispatch}}
      >
        <BrowserRouter>
          <Routes>
            <Route path='/login'element={<Login />} />
            <Route path='/forgetpass'element={<ForgetPass />} />
            <Route path='*' element={<Login />} />
          </Routes>
        </BrowserRouter>
      </AuthContext.Provider>
    )
  }
  if(state.user === "superUser"){
    return (
      <BrowserRouter>
      <div className="float-right">
        <span className="btn btn-sm btn-info m-2 cursor-menu" onClick={toggleDrawer('right', true)}><MenuIcon /></span>
      </div>
       <SwipeableDrawer
        anchor='right'
        open={menu['right']}
        onClose={toggleDrawer('right',false)}
        onOpen={toggleDrawer('right', true)}
      >
        <Box
          role="presentation"
          onClick={toggleDrawer('right',false)}
          onKeyDown={toggleDrawer('right',false)}
        >
          <List>
            <ListItem>
              <div className="text-center">
                <img src="/img/logo_ariztia.png" width="90%"/>
              </div>
            </ListItem>
            <Divider />
            <Divider />
            <Divider />
            <Link to='/dashcuenta'>
              <ListItem className="menu-app-drawler">
                <ListItemIcon><ManageAccountsIcon /></ListItemIcon>
                <ListItemText>Mantenedor Cuentas</ListItemText>
              </ListItem>
            </Link>
            <Link to='/verdatos'>
              <ListItem className="menu-app-drawler">
                <ListItemIcon><FindInPageIcon /></ListItemIcon>
                <ListItemText>Datos Empresas</ListItemText>
              </ListItem>
            </Link>
            
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            <Link to='/salir'>
              <ListItem className="menu-app-drawler">
                <ListItemIcon><LogoutIcon color='error' /></ListItemIcon>
                <ListItemText>Salir</ListItemText>
              </ListItem>
            </Link>
          </List>
        </Box>
      </SwipeableDrawer>
        <div className="user-info">
          <span><small>Empresa: {state.empresa}</small></span><br/>
          <span><small>Usuario: {state.rut}</small></span>
        </div>
        <Routes>
          {/* <Route path='/'element={<MainSU token={state.token} />} /> */}
          <Route path='/'element={<CrearUser token={state.token}/>} />
          <Route path='/dashcuenta'element={<CrearUser token={state.token}/>} />
          <Route path='/verdatos'element={<VerDatoPlantas token={state.token} />} />
          <Route path='/salir' element={<CloseSession />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    )
  }
  if(state.user === 'admin'){
    return (
      <BrowserRouter>
      <div className="float-right">
        <span className="btn btn-sm btn-info m-2 cursor-menu" onClick={toggleDrawer('right', true)}><MenuIcon /></span>
      </div>
       <SwipeableDrawer
        anchor='right'
        open={menu['right']}
        onClose={toggleDrawer('right',false)}
        onOpen={toggleDrawer('right', true)}
      >
        <Box
          role="presentation"
          onClick={toggleDrawer('right',false)}
          onKeyDown={toggleDrawer('right',false)}
        >
          <List>
            <ListItem>
              <div className="text-center">
                <img src="/img/logo_ariztia.png" width="90%"/>
              </div>
            </ListItem>
            <Divider />
            <Divider />
            <Divider />
            <Link to='/dashsuenta'>
              <ListItem  className="menu-app-drawler">
                <ListItemIcon><ManageAccountsIcon /></ListItemIcon>
                <ListItemText>Mantenedor Cuentas</ListItemText>
              </ListItem>
            </Link>
            <Link to='/dashmedidor'>
              <ListItem className="menu-app-drawler">
                <ListItemIcon><SettingsInputSvideoOutlinedIcon /></ListItemIcon>
                <ListItemText>Mantenedor Medidores</ListItemText>
              </ListItem>
            </Link>
            <Link to='/ingresodatos'>
              <ListItem className="menu-app-drawler">
                <ListItemIcon><HistoryEduIcon /></ListItemIcon>
                <ListItemText>Ingreso Datos</ListItemText>
              </ListItem>
            </Link>
            <Link to='/dashdatos'>
              <ListItem className="menu-app-drawler">
                <ListItemIcon><FindInPageIcon /></ListItemIcon>
                <ListItemText>Mantenedor Datos</ListItemText>
              </ListItem>
            </Link>
            <Link to='/graficar'>
              <ListItem className="menu-app-drawler">
                <ListItemIcon><EqualizerIcon /></ListItemIcon>
                <ListItemText>Grafico</ListItemText>
              </ListItem>
            </Link>
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            <Link to='/salir'>
              <ListItem className="menu-app-drawler">
                <ListItemIcon><LogoutIcon color='error' /></ListItemIcon>
                <ListItemText>Salir</ListItemText>
              </ListItem>
            </Link>
          </List>
        </Box>
      </SwipeableDrawer>
        <div className="user-info">
          <span><small>Empresa: {state.empresa}</small></span><br/>
          <span><small>Usuario: {state.rut}</small></span>
        </div>
          <Routes>
            {/* <Route path='/' element={<MainAD token={state.token} />} /> */}
            <Route path='/' element={<MantenedorDatos token={state.token} rut={state.rut} empresa={state.empresa} />} />
            <Route path='/dashmedidor' element={<CrearMedidor token={state.token} rut={state.rut} empresa={state.empresa} />} />
            <Route path='/dashsuenta' element={<MantenedorCuenta token={state.token} rut={state.rut} empresa={state.empresa} />} />
            <Route path='/dashdatos' element={<MantenedorDatos token={state.token} rut={state.rut} empresa={state.empresa} />} />
            <Route path='/ingresodatos' element={<IngresoDatos token={state.token} rut={state.rut} empresa={state.empresa} />} />
            <Route path='/graficar' element={<GraficarChart token={state.token} rut={state.rut} empresa={state.empresa} />} />
            <Route path='/salir' element={<CloseSession />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      
    )
  }
  else{
    return(
      <BrowserRouter>
      <div className="float-right">
        <span className="btn btn-sm btn-info m-2 cursor-menu" onClick={toggleDrawer('right', true)}><MenuIcon /></span>
      </div>
       <SwipeableDrawer
        anchor='right'
        open={menu['right']}
        onClose={toggleDrawer('right',false)}
        onOpen={toggleDrawer('right', true)}
      >
        <Box
          role="presentation"
          onClick={toggleDrawer('right',false)}
          onKeyDown={toggleDrawer('right',false)}
        >
          <List>
            <ListItem>
              <div className="text-center">
                <img src="/img/logo_ariztia.png" width="90%"/>
              </div>
            </ListItem>
            <Divider />
            <Divider />
            <Divider />
            {/* <Link to='/'>
              <ListItem className="menu-app-drawler">
                <ListItemIcon><CottageIcon /></ListItemIcon>
                <ListItemText>Home</ListItemText>
              </ListItem>
            </Link> */}
            <Link to='/verdatos'>
              <ListItem className="menu-app-drawler">
                <ListItemIcon><FindInPageIcon /></ListItemIcon>
                <ListItemText>DashBoard Datos</ListItemText>
              </ListItem>
            </Link>
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            <Link to='/salir'>
              <ListItem className="menu-app-drawler">
                <ListItemIcon><LogoutIcon color='error' /></ListItemIcon>
                <ListItemText>Salir</ListItemText>
              </ListItem>
            </Link>
          </List>
        </Box>
      </SwipeableDrawer>
      <div className="user-info">
        <span><small>Empresa: {state.empresa}</small></span><br/>
        <span><small>Usuario: {state.rut}</small></span>
      </div>
      
          <Routes>
            <Route path='/' element={<VerDatos token={state.token} rut={state.rut} empresa={state.empresa} />} />
            <Route path='/verdatos' element={<VerDatos token={state.token} rut={state.rut} empresa={state.empresa} />} />
            <Route path='/salir' element={<CloseSession />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
      </BrowserRouter>
    )
  }
  // return (
  //   <AuthContext.Provider
  //     value={{state, dispatch}}
  //   >
  //     <>
  //     {
  //     // ?<BrowserRouter>
  //     // <div className="container-fluid text-center mt-3">
  //     //   <div className="d-flex justify-content-end ">
  //     //     <div><Link className="btn btn-sm btn-info mx-2" to='/'>Inicio</Link></div>
  //     //     <div><Link className="btn btn-sm btn-info mx-2" to='/dashcuenta'>Dash Cuenta</Link></div>
  //     //     <div><Link className="btn btn-sm btn-info mx-2" to='/verdatos'>Ver Datos</Link></div>
  //     //     <div><Link className="btn btn-sm btn-danger mx-2" to='/salir'>Salir</Link></div>
  //     //   </div>
  //     // </div>
  //     // <h1>SuperUser</h1>
  //     //   <Routes>
  //     //     <Route path='/'element={<MainSU token={state.token} />} />
  //     //     <Route path='/dashcuenta'element={<CrearUser token={state.token}/>} />
  //     //     <Route path='/verdatos'element={<VerDatoPlantas token={state.token} />} />
  //     //     <Route path='/salir' element={<CloseSession />} />
  //     //     <Route path='*' element={<NotFound />} />
  //     //   </Routes>
  //     // </BrowserRouter>
  //     state.user === 'admin'
  //     ?<BrowserRouter>
  //       <div className="container-fluid text-center mt-3">
  //         <div className="d-flex justify-content-end ">
  //           <div><Link className="btn btn-sm btn-info mx-2" to='/'>Inicio</Link></div>
  //           <div><Link className="btn btn-sm btn-info mx-2" to='/dashsuenta'>Dash Cuenta</Link></div>
  //           <div><Link className="btn btn-sm btn-info mx-2" to='/dashmedidor'>Dash Medidor</Link></div>
  //           <div><Link className="btn btn-sm btn-info mx-2" to='/ingresodatos'>Ingreso Datos</Link></div>
  //           <div><Link className="btn btn-sm btn-info mx-2" to='/dashdatos'>Dash Datos</Link></div>
  //           <div><Link className="btn btn-sm btn-danger mx-2" to='/salir'>Salir</Link></div>
  //         </div>
  //       </div>
  //       <h1>Administrador</h1>
  //         <Routes>
  //           <Route path='/' element={<MainAD token={state.token} />} />
  //           <Route path='/dashmedidor' element={<CrearMedidor token={state.token} rut={state.rut} />} />
  //           <Route path='/dashsuenta' element={<MantenedorCuenta token={state.token} rut={state.rut} />} />
  //           <Route path='/dashdatos' element={<MantenedorDatos token={state.token} rut={state.rut} />} />
  //           <Route path='/ingresodatos' element={<IngresoDatos token={state.token} rut={state.rut} />} />
  //           <Route path='/salir' element={<CloseSession />} />
  //           <Route path='*' element={<NotFound />} />
  //         </Routes>
  //       </BrowserRouter>
  //     :<BrowserRouter>
  //       <div className="container-fluid text-center mt-3">
  //         <div className="d-flex justify-content-end ">
  //           <div><Link className="btn btn-sm btn-info mx-2" to='/'>Inicio</Link></div>
  //           <div><Link className="btn btn-sm btn-info mx-2" to='/verdatos'>Ver Datos</Link></div>
  //           <div><Link className="btn btn-sm btn-info mx-2" to='/salir'>Salir</Link></div>
  //         </div>
  //       </div>
  //       <h1>User</h1>
  //         <Routes>
  //           <Route path='/' element={<MainUS token={state.token} />} />
  //           <Route path='/verdatos' element={<VerDatos token={state.token} />} />
  //           <Route path='/salir' element={<CloseSession />} />
  //           <Route path='*' element={<NotFound />} />
  //         </Routes>
  //     </BrowserRouter>
  //     }
  //     </>
  //   </AuthContext.Provider>
  // );
}

export default App;
