import { Button, FormControl, Input, InputAdornment, InputLabel } from '@mui/material'
import React, { useState } from 'react'
import PasswordIcon from '@mui/icons-material/Password';
import AlternateEmailTwoToneIcon from '@mui/icons-material/AlternateEmailTwoTone';
import { url } from '../../url/url.link';

export default function ForgetPass() {

  const [mail, setMail] = useState({mail: ''})

  const handleChange = (prop) => (event) => {
    setMail({ ...mail, [prop]: event.target.value });
  };

  const handleRecovery = async () => {
    fetch(url+'/forgetpassword',{
      method: 'POST',
      headers: {
        'authorization': "paico 2021",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: mail.mail
      })
    })
    .then(res=>res.json())
    .then(res=>{
      //console.log(res)
      if(res.data.success){
        alert('recuperado')
        window.location.href = '/'
      }else{
        alert(res.data.error+'\n--Revise los datos y Reintentelo--')
      }
    })
    
  }

  return (
    <div className="container">
      <div className="row" style={{height: '100vh'}}>
        <div className="col-12 col-md-6 d-flex align-items-center text-center justify-content-center">
          <img src="/img/logo_ariztia.png" alt="Ariztia" />
        </div>
        <div className="col-12 col-md-6 px-3 fondo d-flex align-items-center text-center justify-content-center bsdw-login " >
          <div className="row">
            <div className="col-12 pt-3 text-left">
              <h4>Recuperar Password</h4>
            </div>
            <div className="col-12 pt-3">
            <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="input-username">
                  Correo
                </InputLabel>
                <Input
                  type="text"
                  value={mail.mail}
                  onChange={handleChange('mail')}
                  id="input-username"
                  startAdornment={
                    <InputAdornment position="start">
                      <AlternateEmailTwoToneIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div className="col-12 pt-3">

            </div>
            <div className="col-12 pt-3">
              <Button fullWidth variant="contained" size="small" endIcon={<PasswordIcon />} onClick={handleRecovery}>Recuperar</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
