import {url} from '../../url/url.link'

export default async function GetMedidormantAD({token, rut, setEmpresa}) {
  //console.log(rut,url, 'getmantAD')
  const listaMedidores = await fetch(url+'/ariztia/getmedidoresdashad',{
    method: 'POST',
    headers: {
      'authorization': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: 'datos',
      rut: rut
    })
  })
  
  let lista = await listaMedidores.json()
  if(!lista.data.success)window.location.href='/salir'
  setEmpresa(lista.data.body.empresa)
  return lista.data.body.medidores
}
