import { url } from "../../url/url.link"

export default async function AddCuentaAD({token, datos, rut}) {
  // console.log('////',token, datos, rut)
  const listaCuenta = await fetch(url+'/ariztia/adaddcta',{
    method: 'POST',
    headers: {
      'authorization': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: datos,
      rut: rut
    })
  })
  
  let lista = await listaCuenta.json()
  if(!lista.data.success)window.location.href='/salir'
  return lista
}
