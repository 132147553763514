import React, { useEffect, useState } from 'react'
import {Table} from '@mui/material/';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormatNumber from '../../component/FormatNumber.component';
import GetDataMantenedor from '../../component/admin/GetDataMantenedor.api';
import EditLecturaMedidorAD from '../../component/admin/EditLecturaMedidorAD.component';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { url } from '../../url/url.link';

export default function MantenedorDatos({token, rut}) {
  const [headers, setHeaders] = useState([])
  const [rows, setRows] = useState([])
  const [empresa, setEmpresa] = useState('')
  const [MesAnno, setMesAnno] = useState({mes: '', anno: '2021'})
  const handleChangeValue = async () => {
    HandleGetData()
  }

  const HandleGetData = async () => {
    //console.log(MesAnno)
    if(MesAnno.mes === '' || MesAnno.anno === ''){
      alert('Faltan Datos por Seleccionar')
      return
    }else{
      let mainData = await GetDataMantenedor({token,rut, setEmpresa, MesAnno})
      // console.log(mainData)
      if(mainData.success){
        setRows(mainData.body.arrAcumGen)
        setHeaders(mainData.body.headers)
      }
    }
  }

  const HandleSetValuesDate = async (e) => {
    await setMesAnno({...MesAnno, [e.target.name]: e.target.value})
  }
  const HandleDownloadFile = async () => {
    //console.log(MesAnno, empresa)

    const listaMedidores = await fetch(url+'/ariztia/onlydownload',{
      method: 'POST',
      headers: {
        'authorization': token,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        fecha: MesAnno.anno+'-'+MesAnno.mes,
        empresa: empresa
      })
    })
    
    let lista = await listaMedidores.json()
    if(!lista.data.success)window.location.href='/salir'
    else{
      window.location.href = url+lista.data.body
      return
    }
  }

  return (
    <>
    <div className="container bsdw-cntinr fondo">
      <div className="row">
        <div className="col">
          <h5>Mantenedor Datos</h5>
        </div>
      </div>
    </div>

    <div className="container bsdw-cntinr fondo mt-3">
      <div className="row">
        <div className="col-12 col-md-4 my-2"> 
          {
            rows.length!==0?
            <div className="downfile-ad" title="Descargar Archivo">
              <IconButton aria-label="Descargar Archivo" size="medium" onClick={HandleDownloadFile} >
                <CloudDownloadOutlinedIcon fontSize="inherit" style={{color: '#fff'}} />
              </IconButton>
            </div>
            :null
          }
          
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Seleccione Mes</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              name="mes"
              id="demo-simple-select"
              value={MesAnno.mes}
              label="Seleccione Mes"
              onChange={HandleSetValuesDate}
            >
              <MenuItem value="01">Enero</MenuItem>
              <MenuItem value="02">Febrero</MenuItem>
              <MenuItem value="03">Marzo</MenuItem>
              <MenuItem value="04">Abril</MenuItem>
              <MenuItem value="05">Mayo</MenuItem>
              <MenuItem value="06">Junio</MenuItem>
              <MenuItem value="07">Julio</MenuItem>
              <MenuItem value="08">Agosto</MenuItem>
              <MenuItem value="09">Septiembre</MenuItem>
              <MenuItem value="10">Octubre</MenuItem>
              <MenuItem value="11">Noviembre</MenuItem>
              <MenuItem value="12">Diciembre</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-12 col-md-4 my-2">
          <FormControl fullWidth size="small">
            <InputLabel id="seelct-anno-ad">Seleccione Año</InputLabel>
              <Select
                labelId="seelct-anno-ad"
                name="anno"
                value={MesAnno.anno}
                label="Seleccione Año"
                onChange={HandleSetValuesDate}
              >
              <MenuItem value="2021">2021</MenuItem>
              <MenuItem value="2022">2022</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-12 col-md-4 my-2"><Button fullWidth variant="contained" onClick={HandleGetData}>Buscar</Button></div>
        <div className="col-12">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="sticky table" size="small">
              <TableHead style={{backgroundColor: '#B5B5B5'}}>
                <TableRow>
                  {
                    headers.map((hrd,index)=>
                      index===0?<TableCell align="left" key={index}>{hrd}</TableCell>
                      :<TableCell align="right" key={index}>{hrd}</TableCell>
                      )
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">{row.nombre}</TableCell>
                    {
                      row.valores.map(rwvlrs=>
                        <TableCell align="right" className="table-cell-view">
                          {
                            rwvlrs.PERSITENCE?FormatNumber(rwvlrs.data)+" "+rwvlrs.umed:
                            <EditLecturaMedidorAD
                              value={FormatNumber(rwvlrs.data)}
                              id={rwvlrs.id}
                              umed={rwvlrs.umed}
                              tipodato={rwvlrs.tipodato}
                              name={row.nombre}
                              rut={rut}
                              fecha={rwvlrs.fecha}
                              empresa={empresa}
                              token={token}
                              handleChangeValue={handleChangeValue}
                            />
                          }
                          
                          </TableCell>                      
                      )
                    }
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
   <br/>
   <br/>
   <br/>
    </>
  )
}
