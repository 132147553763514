import React, { useState } from 'react'
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { Divider, Drawer, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import AddNewCardMedidorAD from './AddNewCardMedidorAD.api';
import { Box } from '@mui/system';

export default function AddMedidorAD({token, rut, empresa, handleDataDrag}) {
  // console.log(data, rut, token)
  const [state, setstate] = useState({bottom: false})
  const [newValue, setNewValue] = useState({
    NOMBRE:'',
    UN_MED:'',
    ESTADO: ''
  })
  const [tipoDato, setTipoDato] = useState('medidor')
  const [geTabs, setTabs] = useState(0)

  let HandleChangeValueLect = async () => {
    let respData = await AddNewCardMedidorAD({rut, token, newValue, empresa, tipoDato})
    handleDataDrag()
    await setstate(false)
    setNewValue({
      NOMBRE:'',
      UN_MED:'',
      ESTADO: ''
    })
  }

  let handleDatosForm = async (e) => {
    setNewValue({...newValue,[e.target.name]: e.target.value})
  }
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={geTabs !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {geTabs === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <div className="">
      <span className="btn btn-sm btn-secondary w-100 my-2" onClick={()=>setstate({'bottom': true})}><AddSharpIcon /> Agregar Medidor</span>
      <Drawer
        anchor='bottom'
        open={state['bottom']}
        onClose={()=>setstate({'bottom': false})}
      >
        <div className="container table-responsive pb-5 drawerHeight">
          <div className="row">
            <div className="col-12 py-3"><h5>Agregar Medidor </h5></div>
            <div className="container-fluid">
              <div className="row">

                <div className="col-12">
                  <span className="float-right">
                    <FormControl component="fieldset">
                      <FormLabel component="legend"  className="p-0 m-0">Tipo de Dato</FormLabel>
                      <RadioGroup
                        onChange={(e)=>setTipoDato(e.target.value)}
                        row
                        className="p-0 m-0"
                        aria-label={tipoDato}
                        defaultValue={tipoDato}
                        name="radio-buttons-group"
                      >
                        <Tooltip title="Medidor: es el cual se calcula un consumo en base a datos anteriormente ingresados por lo cual un dato afecta a otro"  placement="top-start">
                          <FormControlLabel className="p-0 m-0" value="medidor" control={<Radio />} label="Medidor" />
                        </Tooltip>
                        <Tooltip title="Dato Neto: es el cual no afecta el valor anterior o posterior, es solo un dato"  placement="top-start">
                          <FormControlLabel className="p-0 m-0" value="dato_neto" control={<Radio />} label="Dato Neto" />
                        </Tooltip>
                      </RadioGroup>
                    </FormControl>
                  </span>
                </div>
                <div className="col-12 col-md-6 py-2"><TextField fullWidth onChange={handleDatosForm} id="outlined-basic" name="NOMBRE" value={newValue.NOMBRE} label="Nombre" size="small" variant="outlined" /></div>
                <div className="col-12 col-md-3 py-2"><TextField fullWidth onChange={handleDatosForm} id="outlined-basic" name="UN_MED" value={newValue.UN_MED} label="Unidad de Medida" size="small" variant="outlined" /></div>
                <div className="col-12 col-md-3 py-2">
                  <FormControl fullWidth>
                    <InputLabel id="Estado">Estado</InputLabel>
                    <Select
                      labelId="Estado"
                      id="demo-simple-select"
                      label="Estado"
                      size="small"
                      name="ESTADO"
                      value={newValue.ESTADO}
                      onChange={handleDatosForm}
                    >
                      <MenuItem value={true}>Activo</MenuItem>
                      <MenuItem value={false}>Inactivo</MenuItem>
                    </Select>
                  </FormControl>  
                </div>

                <div className="col-12"> 
                  <button className="btn  btn-info w-100" onClick={HandleChangeValueLect}>Agregar Medidor</button>
                </div>

            </div>
            </div>
          </div>
        </div>
        
      </Drawer>
    </div>
  )
}
