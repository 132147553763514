import { Drawer, TextField } from '@mui/material'
import React, { useState } from 'react'
import EditLecturaMedidor from './EditLecturaMedidor.api'

export default function EditLecturaMedidorAD({value, id, umed, name, rut, fecha, empresa,token, handleChangeValue, tipodato}) {
  //console.log('******',tipodato)
  const [state, setstate] = useState({bottom: false})
  const [newValue, setNewValue] = useState('')

  let HandleChangeValueLect = async () => {
    //console.log(newValue, id, umed, name, rut, fecha, empresa)
    let respData = await EditLecturaMedidor({newValue, id, umed, name, rut, fecha, empresa, token,tipodato})
    await setNewValue('')
    await setstate(false)
    handleChangeValue()
  }

  return (
    <div>
      {/* <button style={{padding:'0'}} className="btn  btn-sm btn-secondary w-100" onClick={()=>setstate({'bottom': true})}> Editar Lectura</button> */}
      <span className="click-on-view-mantdata" onClick={()=>setstate({'bottom': true})}>{value} {umed}</span>
      <Drawer
        anchor='bottom'
        open={state['bottom']}
        onClose={()=>setstate({'bottom': false})}
      >
        <div className="container table-responsive pb-5">
          <div className="row">
            <div className="col-12 py-3"><h5>Editar Lectura </h5></div>
            <div className="col-12 col-md-6 py-2"><TextField fullWidth id="outlined-basic" aria-readonly name="actualVal" label="Valor Actual" value={value+umed} size="small" variant="outlined" /></div>
            <div className="col-12 col-md-6 py-2">
              <TextField
                focused
                fullWidth
                type="number"
                id="outlined-basic"
                name="newVal"
                label="Nuevo Valor"
                onChange={(e)=>setNewValue(e.target.value)}
                value={newValue}
                size="small"
                variant="outlined"
              />
            </div>

            <div className="col-12"> 
              <button className="btn  btn-info w-100" onClick={()=>HandleChangeValueLect()}>Actualizar Lectura</button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}
