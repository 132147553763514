import { url } from "../../url/url.link"

export default async function AddNewCardMedidorAD({rut, token, newValue, empresa, tipoDato}) {
  // console.log('addcard',card, empresa)
  const cardUpdate = await fetch(url+'/ariztia/addnewmedidorad',{
    method: 'PUT',
    headers: {
      'authorization': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      rut: rut,
      data: newValue,
      empresa: empresa,
      dato: tipoDato
    })
  })

  
  let cards = await cardUpdate.json()
  if(!cards.data.success)window.location.href='/salir'
  // console.log(cards)
  return cards
}
