import React, { useEffect, useRef, useState } from 'react'
import SelectComp from 'react-select'
import makeAnimated from 'react-select/animated'
import Chart from 'react-apexcharts'
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import EqualizerIcon from '@mui/icons-material/Equalizer';
import GetGraficcoAD from '../../component/admin/GetGraficcoAD.api'
import { url } from '../../url/url.link'
import ChartModule from './ChartModule.view'
const animatedComponents = makeAnimated()

export default function GraficarChart({token, rut}) {
  const [listOptions, setListOptions] = useState([])
  const [SelectMedidor, setSelectMedidor] = useState([])
  const [mes, setMes] = useState('')
  const [anno, setAnno] = useState('2021')
  const [serie, setSerie] = useState([{
        name: 'loading',
        data: [0,0,0,0,0,0,]}])
  const [categori, setCategori] = useState([])

  const HandleSendDataToChart = () => {
    if(mes==='' || anno === '' || SelectMedidor.length === 0 ){
      alert('Debe Seleccionar Todos los Campos')
      return
    }else{
      HandleGetDataGrafic()
    }
  }

  const GetAllMedidoresXBusins = async () => {
    const listaMedidores = await fetch(url+'/ariztia/getmedidorad',{
      method: 'POST',
      headers: {
        'authorization': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        rut: rut,
      })
    })
    
    let lista = await listaMedidores.json()
    if(!lista.data.success)window.location.href='/salir'
    // console.log('/////////',lista.data.body.medidor)
    let medidor = await lista.data.body.medidor.map(mddr=>{
      if(mddr.ESTADO){
        return {value: mddr.NOMBRE, label: mddr.NOMBRE}
      }
    })
    setListOptions(medidor)
  }

  const HandleGetDataGrafic = async () => {
    //console.log('call')
    let data = await GetGraficcoAD({token, rut, mes, anno, SelectMedidor})
    console.log(data)
    await setSerie(data.arrgeneral)
    await setCategori(data.categorias)
  }

  useEffect(() =>{GetAllMedidoresXBusins()}, [])

  // let state = {
          
  //   series: [{
  //     name: 'Net Profit',
  //     data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
  //   }, {
  //     name: 'Revenue',
  //     data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
  //   }, {
  //     name: 'Revenue 2',
  //     data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
  //   }, {
  //     name: 'Revenue 3',
  //     data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
  //   }, {
  //     name: 'Free Cash Flow',
  //     data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
  //   }],
  //   options: {
  //     chart: {
  //       type: 'bar',
  //       height: 350
  //     },
  //     plotOptions: {
  //       bar: {
  //         horizontal: false,
  //         columnWidth: '55%',
  //         endingShape: 'rounded'
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false
  //     },
  //     stroke: {
  //       show: true,
  //       width: 2,
  //       colors: ['transparent']
  //     },
  //     xaxis: {
  //       categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
  //     },
  //     yaxis: {
  //       title: {
  //         text: '$ (thousands)'
  //       }
  //     },
  //     fill: {
  //       opacity: 1
  //     },
  //     tooltip: {
  //       y: {
  //         formatter: function (val) {
  //           return "$ " + val + " thousands"
  //         }
  //       }
  //     }
  //   },
  // };



  // let state = {
  //   series: serie,
  //   options: {
  //     chart: {
  //       dropShadow: {
  //         enabled: true,
  //         top: 0,
  //         left: 0,
  //         blur: 3,
  //         opacity: 0.5
  //       },
  //       type: 'bar',
  //       height: 430
  //     },
  //     plotOptions: {
  //       bar: {
  //         horizontal: false,
  //         dataLabels: {
  //           position: 'top',
  //         },
  //       }
  //     },
  //     dataLabels: {
  //       enabled: false,
  //       offsetX: -6,
  //       style: {
  //         fontSize: '12px',
  //         colors: ['#000']
  //       }
  //     },
  //     stroke: {
  //       show: false,
  //       width: 0,
  //       colors: ['#fff']
  //     },
  //     tooltip: {
  //       shared: true,
  //       intersect: false
  //     },
  //     xaxis: {
  //       categories: categori,
  //     },
  //     fill: {
  //       type: 'gradient',
  //       opacity: 0.5,
  //       gradient: {
  //         shade: 'dark',
  //         gradientToColors: [],
  //         inverseColors: false,
  //         shadeIntensity: 1,
  //         type: 'vertical',
  //         opacityFrom: 1,
  //         opacityTo: 1,
  //         stops: [0, 100,100],
  //       },
  //     },
  //     yaxis: [{
  //       title: {
  //         text: 'Consumo Medidores',
  //       },
  //     }]
  //   },
  // }

  let state = {
          
    series: serie,
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: categori,
      },
      yaxis: {
        title: {
          text: '$ (thousands)'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          }
        }
      }
    },
  
  
  };

  return (
  <>
    <div className="container bsdw-cntinr fondo">
      <div className="row">
        <div className=" col-12"><h5>Grafico</h5></div>
      </div>
    </div>
    <div className="container bsdw-cntinr fondo my-3">
      <div className="row">
        <div className="col-12 col-md-4 my-2">
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Mes</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={mes}
              label="Mes"
              onChange={(e)=>{setMes(e.target.value)}}
            >
            <MenuItem  value="01">Enero</MenuItem >
            <MenuItem  value="02">Febrero</MenuItem >
            <MenuItem  value="03">Marzo</MenuItem >
            <MenuItem  value="04">Abril</MenuItem >
            <MenuItem  value="05">Mayo</MenuItem >
            <MenuItem  value="06">Junio</MenuItem >
            <MenuItem  value="07">Julio</MenuItem >
            <MenuItem  value="08">Agosto</MenuItem >
            <MenuItem  value="09">Septiembre</MenuItem >
            <MenuItem  value="10">Octubre</MenuItem >
            <MenuItem  value="11">Noviembre</MenuItem >
            <MenuItem  value="12">Diciembre</MenuItem >
            </Select>
          </FormControl>
        </div>
        <div className="col-12 col-md-4 my-2">
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Año</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={anno}
              label="Año"
              onChange={(e)=>{setAnno(e.target.value)}}
            >
              <MenuItem value={2021}>2021</MenuItem>
              <MenuItem value={2022}>2022</MenuItem>
            </Select>
          </FormControl>
        </div>
        
        <div className="col-12 col-md-4 my-2 ">
        <Button fullWidth variant="contained" endIcon={<EqualizerIcon />} onClick={()=>HandleSendDataToChart()}>Graficar</Button>
        </div>
        <div className="col-12">
          <label>Seleccione Medidores</label>
          <SelectComp
            closeMenuOnSelect={false}
            components={animatedComponents}
            // defaultValue={[colourOptions[4], colourOptions[5]]}
            isMulti
            options={listOptions}
            onChange={(e)=>{setSelectMedidor(e)}}
            // isDisabled={isLoading}
            placeholder="Seleccione Medidores"
          />
        </div>
        <div className="col-12 py-3">
          {
            
            categori.length!==0?<>
              {/* <Chart
                options={state.options || null}
                series={state.series || null}
                type="bar"
                height="600px"
              /> */}
              <ChartModule serie={serie} categori={categori} /></>
            :"Seleccione Los Datos a Graficar."
          }
         
        </div>
        <div className="col-12 py-3">
          
        </div>
      </div>
    </div>
  </>
  )
}

/** marcuXo marcourrutiam@gmail.com */