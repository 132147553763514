import React from 'react'
import { url } from '../../url/url.link'

export default async function GetGraficcoAD({token, rut, mes, anno,SelectMedidor}) {
  
  const listaMedidores = await fetch(url+'/ariztia/getgraficoad',{
    method: 'POST',
    headers: {
      'authorization': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      rut: rut,
      fecha: anno+'-'+mes,
      toMedidores: SelectMedidor
    })
  })
  
  let lista = await listaMedidores.json()
  if(!lista.data.success)window.location.href='/salir'
  //console.log(lista.data.body)
  return lista.data.body
}
