import { url } from "../../url/url.link"

export default async function DeleteMedidorAD({token, rut, id}) {
  // console.log(token, rut, id)
  const listaCuenta = await fetch(url+'/ariztia/dellmedidorad',{
    method: 'DELETE',
    headers: {
      'authorization': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: id,
    })
  })
  
  let lista = await listaCuenta.json()
  if(!lista.data.success)window.location.href='/salir'
  return lista.data.success
}
