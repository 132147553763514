import { IconButton, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'
import SelectReact from 'react-select'
import makeAnimated from 'react-select/animated'

import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import GetDataGlobalSU from '../../component/superUser/GetDataGlobalSU.api';
import { url } from '../../url/url.link';
import FormatNumber from '../../component/FormatNumber.component';

const animatedComponents = makeAnimated()

export default function VerDatoPlantas({token}) {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [dates, setDates] = useState({mes:'', anno:'2021'})
  const [empresas, setEmpresas] = useState([])
  const [arraysDatosTable, setArraysDatosTable] = useState([])

  const listOptions = [
    { value: 'AGROINDUSTRIAL ARICA S.A.', label: 'AGROINDUSTRIAL ARICA S.A.' },
    { value: 'INDUSTRIAL OCHAGAVIA LTDA', label: 'INDUSTRIAL OCHAGAVIA LTDA' },
    { value: 'AGROINDUSTRIAL EL PAICO S.A.', label: 'AGROINDUSTRIAL EL PAICO S.A.' },
  ]

  const handleDataSelect = async (data) =>{

    // if(data.length===0){
      await setEmpresas([])
      await setArraysDatosTable([])
    // }
    await setData(data)
  }
  const handleChangeDates = async(e) => {
    await setDates({...dates,[e.target.name]:e.target.value})
  }

  const handleFindData = async () => {
    //console.log(data)
    await setIsLoading(true)
    if(data.length === 0 || dates.mes === ''){
      alert('Debe seleccionar todos lo solicitado')
      await setIsLoading(false)
    }else{
      let rspns = await GetDataGlobalSU({data,token, dates})
      //console.log(rspns)
      await setArraysDatosTable(rspns.arrGeneral)
      await setEmpresas(rspns.empresas)
      await setIsLoading(false)
    }
  }
  
  const HandleDownloadFile = async () => {
    //console.log(MesAnno, empresa)

    const listaMedidores = await fetch(url+'/ariztia/downloadn',{
      method: 'POST',
      headers: {
        'authorization': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fecha: dates.anno+'-'+dates.mes,
        empresa: empresas
      })
    })
    
    let lista = await listaMedidores.json()
    if(!lista.data.success)window.location.href='/salir'
    else{
      window.location.href = url+lista.data.body
      return
    }
  }

  return (
    <>
      <div className="container bsdw-cntinr fondo">
        <h5>Datos de Empresas</h5>
      </div>
      <div className="container bsdw-cntinr fondo mt-3">
        <div className="row">
          <div className="col-12 col-md-6 py-2">
          <FormControl fullWidth style={{paddingTop:'5px'}}>
            <InputLabel id="select-date-mes">Seleccione Mes</InputLabel>
            <Select
              size="small"
              labelId="select-date-mes"
              id="demo-simple-select"
              value={dates.mes}
              name="mes"
              label="Seleccione Mes"
              onChange={handleChangeDates}
            >
              <MenuItem value="01">Enero</MenuItem>
              <MenuItem value="02">Febrero</MenuItem>
              <MenuItem value="03">Marzo</MenuItem>
              <MenuItem value="04">Abril</MenuItem>
              <MenuItem value="05">Mayo</MenuItem>
              <MenuItem value="06">Junio</MenuItem>
              <MenuItem value="07">Julio</MenuItem>
              <MenuItem value="08">Agosto</MenuItem>
              <MenuItem value="09">Septiembre</MenuItem>
              <MenuItem value="10">Octubre</MenuItem>
              <MenuItem value="11">Noviembre</MenuItem>
              <MenuItem value="12">Diciembre</MenuItem>
            </Select>
          </FormControl>
          </div>
          <div className="col-12 col-md-6 py-2">
          <FormControl fullWidth style={{paddingTop:'5px'}}>
            <InputLabel id="select-date-mes">Seleccione Año</InputLabel>
            <Select
              size="small"
              labelId="select-date-mes"
              id="demo-simple-select"
              value={dates.anno}
              name="anno"
              label="Seleccione Año"
              onChange={handleChangeDates}
            >
              <MenuItem value="2021">2021</MenuItem>
              <MenuItem value="2022">2022</MenuItem>
            </Select>
          </FormControl>
          </div>
          <div className="col-12 col-md-9 mb-2">
            <SelectReact
                closeMenuOnSelect={false}
                components={animatedComponents}
                // defaultValue={[colourOptions[4], colourOptions[5]]}
                isMulti
                options={listOptions}
                onChange={handleDataSelect}
                placeholder="Seleccione Empresa(s)"
              />
          </div>
          <div className="col-12 col-md-3">
            <Button fullWidth variant="contained" endIcon={null} onClick={()=>handleFindData()} disabled={isLoading}>Buscar Datos</Button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
        
          {/** se recorre el array de empresas */
            empresas.map((cabza, index) => 
            <>
              
              <div className="col-12 my-3  bsdw-cntinr fondo" key={index}>
              <small>Planta: {cabza}</small>
              <div className="table-responsive">
              <table className="table table-hover table-sm bg-white">
                <thead className="thead-dark">
                  <tr>
                    {//console.log(arraysDatosTable[index][cabza])
                      //   arraysDatosTable[index].map(arr=>
                      //console.log(arr)
                        <>
                          {
                            arraysDatosTable[index].header.map((hdr, mp)=>
                              <th className={mp!==0?"text-right":null} key={mp}><small>{hdr}</small></th>
                            )
                          }
                         </>
                      // )
                    }
                  </tr>
                </thead>
                <tbody>
                  
                    {
                      arraysDatosTable[index][cabza].map(dtm=>
                        <tr>
                        {
                          dtm.valores.map((dato,ll)=>
                            <th className={ll!==0?"text-right":null}><small>{dato}</small></th>
                          )
                        }
                        </tr>
                      )
                    }
                  
                </tbody>
              </table></div></div>
            </>
            )
          }
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      {
          arraysDatosTable.length!==0?
          <div className="downfile-ad" title="Descargar Archivo">
            <IconButton aria-label="Descargar Archivo" size="medium" onClick={HandleDownloadFile} >
              <CloudDownloadOutlinedIcon fontSize="inherit" style={{color: '#fff'}} />
            </IconButton>
          </div>
          :null
        }
    </>
  )
}
