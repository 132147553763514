
  var url_r = 'https://pasajeros.apidev.info/scan/consulta?rut='

  export default async function GetDataRut({rut}) {
    const response = fetch(url_r+rut,{method:'GET'})
    let res = await (await response).json()
    if(res[0]){
      return {
        success: true,
        data: {
          nom_corto: res[0].nom_corto,
          cargo: res[0].cargo,
          ceco: res[0].ceco,
          nom_ceco:res[0].nom_ceco
        }
      }
    }else{
      return {
        success: false,
        data: {
        }
      }
    }
  }
  
