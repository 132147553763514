import { url } from "../../url/url.link"

export default async function AddCuentaSU({token, datos}) {
  //console.log(token, datos)
  const listaCuenta = await fetch(url+'/ariztia/suaddcta',{
    method: 'POST',
    headers: {
      'authorization': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: datos
    })
  })
  
  let lista = await listaCuenta.json()
  return lista
}
