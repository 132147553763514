import { url } from "../../url/url.link"

export default async function GetListCuentasAD({token, rut}) {
    //console.log('getlist', rut, token)
    const listaCuenta = await fetch(url+'/ariztia/getlistctaad',{
      method: 'POST',
      headers: {
        'authorization': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        rut:rut
      })
    })
    let Lista = await listaCuenta.json()
    if(!Lista.data.success)window.location.href='/salir'
    return Lista.data.body
  }
