import {url} from '../../url/url.link'

export default async function GetListCount(token) {
  //console.log('getlist', token)
  const listaCuenta = await fetch(url+'/ariztia/getlistcuentas',{
    method: 'POST',
    headers: {
      'authorization': token,
      'Content-Type': 'application/json'
    }
  })
  let Lista = await listaCuenta.json()
  if(!Lista.data.success)window.location.href='/salir'
  return Lista.data.body
}
