import React from 'react'
import { url } from '../../url/url.link'

export default async function EditLecturaMedidor({newValue, id, umed, name, rut, fecha, empresa, token,tipodato}) {
  // console.log(newValue, id, umed, name, rut, fecha, empresa, token)
  const listaMedidores = await fetch(url+'/ariztia/editlecturalistadad',{
    method: 'POST',
    headers: {
      'authorization': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: newValue, 
      id: id, 
      label: umed, 
      title: name, 
      rut: rut,
      feshort: fecha, 
      empresa: empresa,
      tipodato: tipodato
    })
  })
  
  let lista = await listaMedidores.json()
  if(!lista.data.success)window.location.href='/salir'
  return 'marcuXo'
}
