import { url } from "../../url/url.link"


export default async function GetDataMantenedor({token, rut, setEmpresa, MesAnno}) {
	const listaMedidores = await fetch(url+'/ariztia/getdatamantnedorad',{
		method: 'POST',
		headers: {
			'authorization': token,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			rut: rut,
			anno: MesAnno.anno,
			mes: MesAnno.mes
		})
	})
		
	let lista = await listaMedidores.json()
	if(!lista.data.success)window.location.href='/salir'
	setEmpresa(lista.data.body.empresa)
	return lista.data
}
