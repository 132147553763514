export default function FormatFecha(fecha) {
    // let a = new Date(fecha)
    let b = new Date()
		// console.log(b,a)
    // console.log((((((b.getTime()-a.getTime())/1000)))));
		let ya_esta = (fecha === b.toISOString().split('T')[0])
		if(ya_esta){
			return 'esta'
		}
		return
		// else{
		// 	if((((((b.getTime()-a.getTime())/1000)/60)/60)/24) <= 1){
		// 		if((((((b.getTime()-a.getTime())/1000))))<60){
		// 			return 'Hace Segundos.'
		// 		}
		// 		if((((((b.getTime()-a.getTime())/1000)/60)/60)) < 1){
		// 			return ('Hace '+Math.round((((((b.getTime()-a.getTime())/1000)/60))))+' min.')
		// 		}
		// 		if(((((b.getTime()-a.getTime())/1000)/60)) < 1){
		// 			return ('Hace '+Math.round((((((b.getTime()-a.getTime())/1000)))))+' seg.')
		// 		}
		// 		else{
		// 			return ('Hace '+Math.round((((((b.getTime()-a.getTime())/1000)/60)/60)))+' hrs.');
		// 		}
		// 	}
		// 	if((((((b.getTime()-a.getTime())/1000)/60)/60)/24) > 3){
		// 		return (a.toLocaleDateString())
		// 	}
		// 	if((((((b.getTime()-a.getTime())/1000)/60)/60)/24) > 1){
		// 		return ('Hace '+Math.ceil((((((b.getTime()-a.getTime())/1000)/60)/60)/24))+' dias.')
		// 	}
		// 	else{
		// 		return 'Sin dato anterior.'
		// 	}
		// }
}