import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import reChart from 'apexcharts'

function ChartModule({serie, categori}) {
  // console.log(serie.length)
  let series = serie
  const [charged, setCharged] = useState([])
  
  useEffect(() => {
    if(series.length>=1){
      let a_r = []
      serie.map(sr=>{
        if(sr.name === 'CONSUMO AGUA X KG'){
          a_r.push( {
            seriesName: sr.name,
            opposite: true,
            decimalsInFloat: 0,
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: true,
              color: '#008FFB'
            },
            labels: {
              style: {
                colors: '#008FFB',
              }
            },
            title: {
              text: sr.name,
              style: {
                color: '#9C9C9C',
              }
            },
          })
        } else {
          a_r.push( {
            seriesName: sr.name,
            opposite: false,
            decimalsInFloat: 0,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: sr.name,
              style: {
                color: '#9C9C9C',
              }
            },
          })
        }
      })
      setCharged(a_r)
      // reChart.exec('chart-one', 'updateSeries', [{series:charged}])
      reChart.exec('chart-one', 'updateOptions', {
        tooltip: {
          y: {
            formatter: function(value,{ seriesIndex, w}){
              return value +" "+ w.config.series[seriesIndex].unmed
            }
          }
        },
      })

    }
  }, [serie])

  let state = {
          
    series: serie,
    options: {
      chart: {
        id: 'chart-one',
        background: '#fff',
        type: 'line',
        stacked: false
      },
      plotOptions: {
        line:{
          fontSize: '12px',
          fontWeight: 'bold'
        },
        bar:{
          dataLabels:{
            offset: -10
          },
          columnWidth: '30%',
          barHeight: '50%',
          distributed: false,
        }
        },
        stroke: {
          curve: 'smooth'
        },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2
      },
      xaxis: {
        categories: categori,
      },
      yaxis: charged,
      tooltip: {
        y: {
          formatter: function(value,{ seriesIndex, w}){
            return value +" "+ w.config.series[seriesIndex].unmed
          }
        },
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        },
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40
      }
    },
  
  
  };

  
  // plotOptions: {
  //   columnWidth: '10%',
  //   distributed: true,
  // },

    return (
      <div>
        <ReactApexChart
          options={state.options || null}
          series={state.series || null}
          height="600px"
        />
      </div>
    )
}

export default ChartModule
