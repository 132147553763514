import './CrearUsuario.css'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined';
import EditMenu from '../../component/superUser/EditMenu.component';
import AddCuenta from '../../component/superUser/AddCuenta.component';
import GetListCount from '../../component/superUser/GetListCount.component';
import { useEffect, useState } from 'react';

export default function CrearUser({token}) {
  
  const [listCta, setListCta] = useState([])
  const [rendr, setRendr] = useState('false')

  const listaCuentas = async () => {
    let lista = await GetListCount(token)
    await setListCta(lista)
  }

  if(rendr){
    listaCuentas()
    setRendr(false)
  }

  useEffect(() => {
   listaCuentas()
  }, [])

  return (
    <>
    <div className="container bsdw-cntinr fondo">
      <h5>Mantenedor de Usuario</h5>
    </div>
    <div className="container fondo bsdw-cntinr mt-3">
      <div className="row px-3 ">
        <div className="col-12 text-center align-content-center pb-2">
          <AddCuenta token={token} setRendr={setRendr} />
        </div>
        <div className="col-12 table-responsive">
          <table className="bg-white table table-sm table-bordered">
          <thead className="thead-light">
            <tr>
              <th className="text-center" scope="col">Planta</th>
              <th className="text-center" scope="col">Rut</th>
              <th className="text-center" scope="col">Nombre</th>
              <th className="text-center" scope="col">Correo</th>
              <th className="text-center" scope="col">Perfil</th>
              <th className="text-center" scope="col">Estado</th>
              <th className="text-center" scope="col">Editar</th>
            </tr>
          </thead>
          <tbody>
            {
              listCta.map((cta, index) =>
                <tr key={index}>
                  <td className="text-center">{cta.empresa}</td>
                  <td className="text-center">{cta.rut}</td>
                  <td className="text-center">{cta.nombre}</td>
                  <td className="text-center">{cta.user}</td>
                  <td className="text-center">{cta.rol}</td>
                  <td className="text-center">
                    {cta.state?
                    <AccountCircleOutlinedIcon color="success" />
                    :<NoAccountsOutlinedIcon color="error" />
                    }
                  </td>
                  <td className="text-center"><EditMenu token={token} data={cta} setRendr={setRendr}/></td>
                </tr>
               )
            }
          </tbody>
        </table>
        
        </div>
      </div>
    </div>
    </>
  )
}
