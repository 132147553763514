import React from 'react'
import { url } from '../../url/url.link'

export default async function UpdateMedidorAD({id, rut, newValue, empresa, token,tipoDato}) {
  console.log('updte', newValue, tipoDato)

  const listaCuenta = await fetch(url+'/ariztia/updatemedidorad',{
    method: 'POST',
    headers: {
      'authorization': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: id,
      data: newValue,
      rut: rut,
      tipodato: tipoDato
    })
  })
  
  let lista = await listaCuenta.json()
  if(!lista.data.success)window.location.href='/salir'
  return lista.data.success
}
