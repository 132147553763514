import { Button,  Drawer,FormControl,InputLabel,MenuItem,Select,TextField } from '@mui/material';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';

import React, { useState } from 'react'
import EditDataAD from './EditDataAD.api';


export default function EditMenuAD({token, data, setRendr}) {
  const [datosU, setDatosU] = useState(data)
  const [state, setState] = useState({bottom: false});
  const [estado, setestado] = useState(false)

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
     
    }
    setState({ ...state, [anchor]: open });
  };

  const handlePerfil = async (e) => {
    setDatosU({...datosU,[e.target.name]:e.target.value})
  }

  const handleEstado = async (e) => {
    if(e.target.value==='Activado')setDatosU({...datosU,state: true})
    else setDatosU({...datosU,state: false})
  }

  const handleUpdateData = async () => {
    setestado(true)
    //console.log(datosU)
    const fetchData = await EditDataAD({token,datosU})
    let respo = await fetchData
    if(respo.data.success){
      setState({ ...state, 'bottom': false });
      mutador()
      setestado(false)
    }
      
  }

  const mutador = async ()=>setRendr('true')
//console.log("el perfil",perfil)
  return (
    <div>
          <Button style={{padding:'0'}} onClick={toggleDrawer('bottom', true)}><ManageAccountsOutlinedIcon fontSize="medium" /></Button>
          <Drawer
            anchor='bottom'
            open={state['bottom']}
            onClose={toggleDrawer('bottom', false)}
          >
            <div className="container table-responsive pb-5">
              <div className="row">
                <div className="col-12 py-3"><h5>Editar Datos</h5></div>
                <div className="col-12 col-md-6 col-lg-4 py-2"><TextField fullWidth id="outlined-basic" label="nombre" name="nombre" size="small" onChange={handlePerfil} variant="outlined" value={datosU.nombre} /></div>
                <div className="col-12 col-md-6 col-lg-4 py-2"><TextField fullWidth id="outlined-basic" label="user" name="user" size="small" onChange={handlePerfil} variant="outlined" value={datosU.user} /></div>
                <div className="col-12 col-md-6 col-lg-4 py-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Perfil</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={datosU.rol}
                      label="Perfil"
                      size="small"
                      name="rol"
                      onChange={handlePerfil}
                    >
                      <MenuItem value={'admin'}>Admin</MenuItem>
                      <MenuItem value={'user'}>User</MenuItem>
                    </Select>
                  </FormControl>  
                </div>
                <div className="col-12 col-md-6 col-lg-4 py-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={datosU.state?'Activado':'Desactivado'}
                      size="small"
                      label="Estado"
                      name="state"
                      onChange={handleEstado}
                    >
                      <MenuItem value={'Activado'}>Activado</MenuItem>
                      <MenuItem value={'Desactivado'}>Desactivado</MenuItem>
                    </Select>
                  </FormControl>  
                </div>
                <div className="col pt-2 mt-1">
                  <button className="btn btn-sm btn-info w-100" onClick={handleUpdateData}>
                    {
                      estado?"cargando":"Guardar Datos"
                    }
                  </button>
                </div>
              </div>
            </div>
          </Drawer>
    </div>
  );
}
