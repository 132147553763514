import React, { useEffect, useState } from 'react'
import './MantenedorDatos.css'
import GetMedidormantAD from '../../component/admin/GetMedidormantAD.component'
import {FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import AddLecturaMedidorAD from '../../component/admin/AddLecturaMedidorAD.api';
import FormatNumber from '../../component/FormatNumber.component';
import FormatFecha from '../../component/FormatFecha.component';

export default function IngresoDatos({token,rut}) {
  const [empresa, setEmpresa] = useState('')
  const [medidores, setMedidores] = useState([])
  const [valMed, setValMed] = useState([])
  const [isLoadBTN, setIsLoadBTN] = useState(true)
  const [isSaveData, setIsSaveData] = useState([])

  const GetMedidoresView = async () => {
    let medidor = await GetMedidormantAD({token,rut,setEmpresa})
    await setMedidores(medidor)
  }

  const handleChange = async (valor, name, unidM, plta, consumo,tipoDato) => {
    setValMed({...valMed,[name]:[name,valor, unidM, plta,consumo, rut, await toHoy(), (new Date()), tipoDato]})
  }

  const toHoy = async () => {
    let a = new Date()
    let d = a.getDate()<=9?('0'+a.getDate()):a.getDate()
    let m = (a.getMonth()+1)<=10?'0'+(a.getMonth()+1):(a.getMonth()+1)
    let y = a.getFullYear()
    let f = y+'-'+m+'-'+d
    return await f
  }

  const HandleSaveLectura = async () => {
    //console.log(valMed)
    setIsLoadBTN(false)
    let resp = await AddLecturaMedidorAD({token, valMed})
    if(resp.success){
      setIsSaveData(resp.body)
      setIsLoadBTN(true)
      setValMed([])
      GetMedidoresView()
    }
  }

  useEffect(() => {
    GetMedidoresView()
  }, [])

  return (
    <>
    <div className="container bsdw-cntinr fondo">
      <h5>Ingreso Datos</h5>
    </div>
    <div className="container bsdw-cntinr fondo mt-3">
      <div className="row">
        <div className="col-12"><h5>Ingreso Lecturas </h5></div>
        {
          medidores.map((medrd, index) => 
          medrd.PERSITENCE?null:
          FormatFecha(medrd.FESHORT)==='esta'?null
          :<div className="col-12 col-sm-6 col-lg-4 col-xl-3 py-2 px-4 ">
            
            <div className="row" key={index}>
              <div className="col-12 py-2 pt-3 dim-ing" key={index}>
                <FormControl fullWidth size="small" variant="outlined" >
                  {/* {console.log(medrd.PERSITENCE)} */}
                  <InputLabel htmlFor="outlined-adornment-password">{medrd.NOMBRE}</InputLabel>
                  <OutlinedInput
                    label={medrd.NOMBRE}
                    type="number"
                    id="outlined-adornment-weight"
                    name={medrd.NOMBRE}
                    onChange={(e)=>handleChange(e.target.value,medrd.NOMBRE,medrd.UN_MED,medrd.EMPRESA, medrd.TIPO_DATO==='medidor'?medrd.VALANT===0?0:(e.target.value-medrd.VALANT):e.target.value,medrd.TIPO_DATO)}
                    endAdornment={<InputAdornment position="end">{medrd.UN_MED}</InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                  {/* {console.log(medrd.TIPO_DATO)} */}
                  <FormHelperText id="outlined-weight-helper-text">Valor Anterior: {FormatNumber(medrd.VALANT)} {medrd.UN_MED}</FormHelperText>
                  {/* <FormHelperText className="text-right" id="outlined-weight-helper-text" index={index}>{FormatFecha(medrd.FECHA)}</FormHelperText> */}
                </FormControl>
              </div>
            </div>
          </div>
          )
        }

        <div className="col-12 pt-3"><button className="btn btn-sm btn-success w-100" onClick={HandleSaveLectura} disabled={valMed.length===0?true:false}>{
          isLoadBTN?'Guardar Datos'
          :<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Cargando...</>
        }</button></div>
      </div>
    </div>

    </>
  )
}
