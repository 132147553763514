import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { url } from '../../url/url.link'

export default function SelectPlanta({token, setEmpresas, empresas}) {
  
  const [lista, setLista] = useState([])
  const handleListaEmpresas = async () => {
    const listFetch = await fetch(url+'/ariztia/getlistaempresasgl',{
      method: 'POST',
      headers: {
        'authorization': token,
        'Content-Type': 'application/json'
      }
    })
    
    let lista = await listFetch.json()
    if(!lista.data.success)window.location.href='/salir'
    setLista(lista.data.body)
  }

  useEffect(() => {
    handleListaEmpresas()
  }, [])
  return (
    <FormControl fullWidth>
      <InputLabel id="planta">Planta</InputLabel>
      <Select
        labelId="planta"
        id="demo-simple-select"
        label="planta"
        size="small"
        name='empresa'
        value={empresas}
        onChange={(e)=>setEmpresas(e.target.value)}
      >
        {
          lista.map((lst, index)=>
          <MenuItem value={lst.NOMBRE} key={index}>{lst.NOMBRE}</MenuItem>
            )
        }
      </Select>
    </FormControl>  
  )
}
