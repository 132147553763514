import {url} from '../../url/url.link'
export default async function GetMedidor({rut, token,setEmpresa}) {
  const listaCuenta = await fetch(url+'/ariztia/getmedidorad',{
    method: 'POST',
    headers: {
      'authorization': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      rut: rut
    })
  })

  
  let lista = await listaCuenta.json()
  if(!lista.data.success)window.location.href='/salir'
  //let cards = await lista.data.body
  setEmpresa(lista.data.body.empresa)
  //console.log(lista)
  return await lista.data.body.medidor
  //return await lista.data.body
}
