import { url } from '../../url/url.link'

export default async function AddLecturaMedidorAD({token, valMed}) {
  //console.log({token, valMed})
  const listaMedidores = await fetch(url+'/ariztia/savewritead',{
    method: 'PUT',
    headers: {
      'authorization': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: valMed,
    })
  })
  
  let lista = await listaMedidores.json()
  if(!lista.data.success)window.location.href='/salir'
  
  return lista.data
}
