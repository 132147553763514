import React from 'react'
import { url } from '../../url/url.link'

export default async function GetDataGlobalSU({data,token, dates}) {
  //console.log('gtData////a',data, token)
  const listaCuenta = await fetch(url+'/ariztia/getalldatabusines',{
    method: 'POST',
    headers: {
      'authorization': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: data,
      mes: dates.mes,
      anno: dates.anno
    })
  })
  
  let lista = await listaCuenta.json()
  if(!lista.data.success)window.location.href='/salir'
  //console.log(lista.data.body)
 return await lista.data.body
}
