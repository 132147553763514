import {Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GetMedidor from '../../component/admin/GetMedidorAD.api'
import './CrearMedidor.css'
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import DoNotDisturbAltSharpIcon from '@mui/icons-material/DoNotDisturbAltSharp';
import EditMedidorAD from '../../component/admin/EditMedidorAD.component';
import AddMedidorAD from '../../component/admin/AddMedidorAD.conponent';
import DeleteMedidorAD from '../../component/admin/DeleteMedidorAD.api';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    borderRadius: 5
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


export default function CrearMedidor({token,rut}) {
  //console.log(token,rut)
  const [dataMedidor, setDataMedidor] = useState([])
  const [dataChange, setDataChange] = useState({})
  const [empresa, setEmpresa] = useState('')
  
  /** obtiene un array con todo el data de los medidores */
  const handleDataDrag = async () => {
    let data = await GetMedidor({rut, token, setEmpresa})
    // console.log(data)
    setDataMedidor(data)
  }

  const HandleDeleteMedidor = async (id, name) => {
    let dr = window.confirm('Confirme Si quiere Eliminar el medidor ( '+name+' ).')
    if(dr){
      let del = await DeleteMedidorAD({token, rut, id})
      if(del)handleDataDrag()
    }
    else return
  }

  useEffect(() => {
    handleDataDrag()
  }, [])

  
  return (
    <>
    <div className="container bsdw-cntinr fondo">
      <h5>Mantenedor Medidores</h5>
    </div>
    <div className="container mt-3 bsdw-cntinr fondo">
      <div className="row">
        <div className="col-12">
          <AddMedidorAD token={token} rut={rut} empresa={empresa} handleDataDrag={handleDataDrag} />
        </div>
        <div className="col-12">
        <TableContainer  sx={{ maxHeight: 440 }}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label=" dense table" size="small">
              <TableHead >
                <TableRow >
                  <StyledTableCell align="left" className="table-header">Planta</StyledTableCell>
                  <StyledTableCell align="right" >Nombre</StyledTableCell>
                  <StyledTableCell align="right" >Unidad Medida</StyledTableCell>
                  <StyledTableCell align="right" >Estado</StyledTableCell>
                  <StyledTableCell align="right" >Accion</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  dataMedidor.map((mddr, index)=>
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{backgroundColor: 'white'}}>
                      <TableCell component="th" scope="row">{mddr.EMPRESA}</TableCell>
                      <TableCell align="right">{mddr.NOMBRE}</TableCell>
                      <TableCell align="right">{mddr.UN_MED}</TableCell>
                      <TableCell align="right">{mddr.ESTADO?<CheckCircleOutlineSharpIcon color='success' />:<DoNotDisturbAltSharpIcon color='error' />}</TableCell>
                      <TableCell align="right">
                        {/* {console.log(mddr)} */}
                        {
                          mddr.PERSITENCE?null
                          :<div className="d-flex flex-row justify-content-end">
                          <span className="sel-table-ad mr-2">
                            <EditMedidorAD data={mddr} token={token} rut={rut} empresa={empresa} handleDataDrag={handleDataDrag}/>
                          </span>
                          <span className="sel-table-ad mr-2" onClick={()=>HandleDeleteMedidor(mddr._id,mddr.NOMBRE)}><DeleteForeverIcon color="error" /></span>
                        </div>
                        
                        }
                        
                      </TableCell>
                    </TableRow>
                  )
                }
                
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
    </>
  )
}