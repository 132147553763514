import { Drawer,FormControl,InputLabel,MenuItem,Select,TextField } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';


import React, { useState } from 'react'
import GetDataRut from './GetDataRut.component'

import AddCuentaSU from './AddCuentaSU.api';
import SelectPlanta from './SelectPlanta.component';

export default function AddCuenta({token, setRendr}) {
  //console.log('addCuenta', token)  
  const [empresas, setEmpresas] = useState('')
  const [state, setState] = useState({bottom: false});
  const [datos, setDatos] = useState({
    empresa:'',
    nombre: '',
    rut: '',
    user: '',
    rol:'',
    cargo:'',
    ceco:'',
    nom_ceco:'',
    state:true,
  })
  const [isRut, setIsRut] = useState(true)

  const [btnState, setbtnState] = useState(false)
  
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleDatosForm = async (e) => {
    if(e.target.name === 'rut'){
      let rut_ = e.target.value
      var clean = rut_.replace(/-/g,"")
      var largo = clean.length
      var prin = clean.substr(0,(largo-1));
      var nal = clean.substr((largo-1),largo)
      rut_ = prin+"-"+nal
      //console.log(rut_)
      await setDatos({...datos,[ e.target.name]:rut_})
      if(e.target.name === 'rut' && e.target.value.length >= 9) {
        handleGetRut(e.target.value)
      }
    }else{
      await setDatos({...datos,[ e.target.name]:e.target.value})
    }
  }

  const handleEstado = async (e) => {
    if(e.target.value==='Activado')setDatos({...datos,state: true})
    else setDatos({...datos,state: false})
  }

  /** envia los datos al componente AddCuenta.api */
  const handleSendData = async () => {
    datos.empresa = empresas
    //console.log(datos)
    const addCTa = await AddCuentaSU({token, datos})
    if(addCTa.data.success){
      setState({ ...state, 'bottom': false });
      mutador()
      setRendr(false)
      setDatos({
        empresa:'',
        nombre: '',
        rut: '',
        user: '',
        rol:'',
        cargo:'',
        ceco:'',
        nom_ceco:'',
        state:true,
      })
    }else{
      alert(addCTa.data.error)
    }
  } 
  const mutador = async ()=>setRendr('true') 

  const handleGetRut = async (rut) => {
    let dataRut = await GetDataRut({rut:rut})
    //console.log('//',dataRut.data)
    if(await dataRut.success){
      setDatos({...datos,
        cargo: dataRut.data.cargo,
        ceco: dataRut.data.ceco,
        nom_ceco: dataRut.data.nom_ceco,
        nombre: dataRut.data.nom_corto,
        rut: rut,
      })
      setIsRut(true)
    }else{
      //console.log(dataRut.success)
      setDatos({...datos,
        cargo: '',
        ceco: '',
        nom_ceco: '',
        nombre: '',
        rut: rut,
      })
      setIsRut(false)
    }
    
  }

  
//console.log("el perfil",perfil)
  return (
    <div>
          <button style={{padding:'0'}} className="btn  btn-sm btn-secondary w-100" onClick={toggleDrawer('bottom', true)}><AddOutlinedIcon  /> Agregar Cuenta</button>
          <Drawer
            anchor='bottom'
            open={state['bottom']}
            onClose={toggleDrawer('bottom', false)}
          >
            <div className="container table-responsive pb-5">
              <div className="row">
                <div className="col-12 py-3"><h5>Crear Cuenta</h5></div>
                <div className="col-12 col-md-6 col-lg-4 py-2">
                  <SelectPlanta setEmpresas={setEmpresas} empresaV={empresas} token={token} />
                </div>
                <div className="col-12 col-md-6 col-lg-4 py-2"><TextField fullWidth id="outlined-basic" name="rut" label="Rut" size="small" onChange={handleDatosForm} value={datos.rut} variant="outlined" /></div>
                <div className="col-12 col-md-6 col-lg-4 py-2"><TextField fullWidth id="outlined-basic" name="nombre" disabled={isRut?true:false} label="Nombre" size="small" onChange={handleDatosForm} value={datos.nombre} variant="outlined" /></div>
                <div className="col-12 col-md-6 col-lg-4 py-2"><TextField fullWidth id="outlined-basic" name="user" label="Correo" size="small" onChange={handleDatosForm} value={datos.user} variant="outlined" /></div>
                <div className="col-12 col-md-6 col-lg-4 py-2">
                  <FormControl fullWidth>
                    <InputLabel id="perfil">Perfil</InputLabel>
                    <Select
                      labelId="perfil"
                      id="demo-simple-select"
                      label="Perfil"
                      size="small"
                      name="rol"
                      value={datos.rol}
                      onChange={handleDatosForm}
                    >
                      <MenuItem value={'superUser'}>SuperUser</MenuItem>
                      <MenuItem value={'admin'}>Admin</MenuItem>
                      <MenuItem value={'user'}>User</MenuItem>
                    </Select>
                  </FormControl>  
                </div>
                <div className="col-12 col-md-6 col-lg-4 py-2">
                  <FormControl fullWidth>
                    <InputLabel id="estado">Estado</InputLabel>
                    <Select
                      labelId="estado"
                      id="estado_select"
                      label="Estado"
                      size="small"
                      name="state"
                      value={datos.state?'Activado':'Desactivado'}
                      onChange={handleEstado}
                    >
                      <MenuItem value={'Activado'}>Activado</MenuItem>
                      <MenuItem value={'Desactivado'}>Desactivado</MenuItem>
                    </Select>
                  </FormControl>  
                </div>
                <div className="col-12 col-md-6 col-lg-4 py-2"> 
                  <button className="btn btn-sm btn-info w-100" onClick={handleSendData}>
                    {
                      btnState?"Cargando":"Crear Cuenta"
                    }
                    </button>
                </div>
              </div>
            </div>
          </Drawer>
    </div>
  );
}
