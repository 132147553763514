import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import { Drawer, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip } from '@mui/material';
import UpdateMedidorAD from './UpdateMedidorAD.api';

export default function EditMedidorAD({data, token, rut, empresa, handleDataDrag}) {
  //console.log(data)
  const [state, setstate] = useState({bottom: false})
  const [newValue, setNewValue] = useState({
    NOMBRE:data.NOMBRE,
    UN_MED:data.UN_MED,
    ESTADO: data.ESTADO
  })
  const [tipoDato, setTipoDato] = useState(data.TIPO_DATO)


  let HandleChangeValueLect = async (id) => {
    // console.log(id, newValue)
    let respData = await UpdateMedidorAD({id, rut, newValue, empresa, token, tipoDato})
    // await setNewValue('')
    handleDataDrag()
    await setstate(false)
  }

  let handleDatosForm = async (e) => {
    setNewValue({...newValue,[e.target.name]: e.target.value})
  }

  return (
    <div>
      <span onClick={()=>setstate({'bottom': true})}><EditIcon color='info' /></span>
      <Drawer
        anchor='bottom'
        open={state['bottom']}
        onClose={()=>setstate({'bottom': false})}
      >
        <div className="container table-responsive pb-5">
          <div className="row">
            <div className="col-12 py-3"><h5>Editar Medidor </h5></div>
            <div className="col-12">
              <span className="float-right">
                <FormControl component="fieldset">
                  <FormLabel component="legend"  className="p-0 m-0">Tipo de Dato</FormLabel>
                  <RadioGroup
                    onChange={(e)=>setTipoDato(e.target.value)}
                    row
                    className="p-0 m-0"
                    aria-label={tipoDato}
                    defaultValue={tipoDato}
                    name="radio-buttons-group"
                  >
                    <Tooltip title="Medidor: es el cual se calcula un consumo en base a datos anteriormente ingresados por lo cual un dato afecta a otro"  placement="top-start">
                      <FormControlLabel className="p-0 m-0" value="medidor" control={<Radio />} label="Medidor" />
                    </Tooltip>
                    <Tooltip title="Dato Neto: es el cual no afecta el valor anterior o posterior, es solo un dato"  placement="top-start">
                      <FormControlLabel className="p-0 m-0" value="dato_neto" control={<Radio />} label="Dato Neto" />
                    </Tooltip>
                  </RadioGroup>
                </FormControl>
              </span>
            </div>

            <div className="col-12 col-md-6 py-2"><TextField fullWidth onChange={handleDatosForm} id="outlined-basic" name="NOMBRE" value={newValue.NOMBRE} label="Nombre" size="small" variant="outlined" /></div>
            <div className="col-12 col-md-3 py-2"><TextField fullWidth onChange={handleDatosForm} id="outlined-basic" name="UN_MED" value={newValue.UN_MED} label="Unidad de Medida" size="small" variant="outlined" /></div>
            <div className="col-12 col-md-3 py-2">
              <FormControl fullWidth>
                <InputLabel id="Estado">Estado</InputLabel>
                <Select
                  labelId="Estado"
                  id="demo-simple-select"
                  label="Estado"
                  size="small"
                  name="ESTADO"
                  value={newValue.ESTADO}
                  onChange={handleDatosForm}
                >
                  <MenuItem value={true}>Activo</MenuItem>
                  <MenuItem value={false}>Inactivo</MenuItem>
                </Select>
              </FormControl>  
            </div>

            <div className="col-12"> 
              <button className="btn  btn-info w-100" onClick={()=>HandleChangeValueLect(data._id)}>Actualizar Medidor</button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}
